import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { fetchPostalCodes } from '../../redux/Actions';
import { setValue } from '../../utility';
import { fetchGetShopByPinCode } from '../../redux/Actions/checkoutPageActions';
import { DebounceInput } from 'react-debounce-input';
// import BookNow from './index';

// const postalCode = [
//     { content: "GU111BH" },
//     { content: "GU111KD" },
//     { content: "GU111MH" },
// ];

function BookNow() {
    const [postalCodeList, setPostalCodeList] = useState([])
    const [postalCodeValue, setPostalCodeValue] = useState("")
    const dispatch = useDispatch<any>();
    const state: any = useSelector(state => state);
    const navigate = useNavigate();
    // const base_url = "https://revamp.dreamguystech.com"


    // const fetchData = async () => {
    //     const base_url = "https://revamp.dreamguystech.com";
    //     try {
    //         const bodyFormData = new FormData();
    //         bodyFormData.append('keyword', 'Gu11');
    //         // res.header('Access-Control-Allow-Methods', 'GET, POST');
    //         const response = await axios({
    //             method: "post",
    //             url: `${base_url}/api/get_postal_codes`,
    //             data: bodyFormData,
    //             headers: { "Accept": "application/json", 'Access-Control-Allow-Methods': 'GET, POST' },
    //         })
    //         // const response = await axios.post(`${base_url}/api/get_postal_codes`,{keyword:"Gu11"});
    //         // const result = await response.then(response => response);

    //         if (response) {
    //             setPostalCodeList(response.data.data[0])
    //             
    //         }
    //     } catch (error) {
    //         
    //     }
    // }


    useEffect(() => {
        if (state) {
            setPostalCodeList(state.postalCodeList);
            // setValue("postalCode",state.postalCodeList[0]?.postcode)
        }

    }, [state])

    // useEffect(() => {
    //     // fetchData();
    //     dispatch(fetchPostalCodes())
    //     // dispatch(fetchPostalCodes());
    // }, [])

    const handleChange = (e: any) => {
        const result = e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
        // setPostalCodeList(e.target.value);
        const { value } = e.target;
        const updateValue = value.replace(/\s/g,'')
        if (value.length > 0) {
            dispatch(fetchPostalCodes(updateValue));
        }
        setPostalCodeValue(updateValue);

    }
    const onSearch = (searchTerm: any) => {
        // setPostalCodeList(searchTerm);
        setPostalCodeValue(searchTerm);
        setValue("postalCode",searchTerm.toUpperCase())
        // dispatch(fetchPostalCodes(searchTerm));


    };
    const handleBooknow = async () => {
        const isValid = state.postalCodeList.some((item:any) => item.postcode === postalCodeValue.toUpperCase());
        setValue("postalCode",postalCodeValue.toUpperCase())
        const results = await dispatch(fetchGetShopByPinCode(JSON.stringify(postalCodeValue)));
        
        if (!results) {
            navigate("/areaNotCovered");
            return;
        }
        if(isValid){
            navigate("/productLists");
        }else{
            navigate("/areaNotCovered");
        }
        
    }
    return (
        <div className="home_single_search">

            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-lg-7">
                        <div className='banner-search-wrap'>
                            <h1>A Professional <span>Dry Cleaning &amp; Laundry Service</span> in the UK</h1>
                            <p className="d-none d-md-block">We offer laundry services to both homes and businesses in the UK Our highly trained staff are always on hand to assist you with all your laundry needs.</p>
                            <div className="postcodeform">
                                {/* <input type="text" id="postcode-input" value={postalCodeValue} name="pincode" placeholder="Enter Zipcode" className="ui-autocomplete-input" onChange={handleChange} autoComplete="off" /> */}
                                <DebounceInput debounceTimeout={500} value={postalCodeValue} placeholder="Enter Zipcode" className="ui-autocomplete-input" onChange={handleChange} autoComplete="off" />

                                <button disabled={postalCodeValue.length === 0} onClick={handleBooknow} className="btn hover-btn" id="book_now_btn" type="button">Book Now</button>
                                <div className="dropdown">
                                    {postalCodeList
                                        .filter((item: any) => {
                                            const searchTerm = postalCodeValue.toLowerCase();
                                            const mainValue = item.postcode.toLowerCase();

                                            return (
                                                searchTerm &&
                                                mainValue.startsWith(searchTerm) &&
                                                mainValue !== searchTerm
                                            );
                                        })
                                        .slice(0, 10)
                                        .map((item: any) => (
                                            <div
                                                onClick={() => onSearch(item.postcode)}
                                                className="dropdown-row"
                                                key={item.postcode}
                                            >
                                                <i className="fas fa-map-marker-alt"></i>{item.postcode}
                                            </div>
                                        ))}
                                </div>
                                {/* <ul id="ui-id-1" tabIndex={0} className="ui-menu ui-widget ui-widget-content ui-autocomplete ui-front" style={{ top: "477.688px", left: "104.5px", width: "461.266px", display: "none" }}>
                                        <li className="ui-menu-item">
                                            <div id="ui-id-4" tabIndex={-1} className="ui-menu-item-wrapper">GU111BH</div>
                                        </li>
                                    </ul> */}



                                {/* <form method="POST" action="https://revamp.dreamguystech.com/selection" accept-charset="UTF-8" id="postForm">
                                    <input name="_token" type="hidden" value="weQWHBzpwFRfH1sjw4hbF0vHiMbe3AsgTSQB2vnP" />
                                    <input name="postalCode" type="hidden" value="" /> */}
                                    {/* <input className="btn btn-default hidden" type="submit" value="Submit" /> */}
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default BookNow;